<template>
  <div>
    <h5 class="text-center">{{ titleText }}</h5>
    <hr />
    <div class="d-flex flex-column justify-content-center">
      <BLink
          v-for="(p, i) in products"
          :key="i"
          class="btn btn-default mb-2 w-full"
          :href="p.link"
          :style="{
        'background': buttonText,
        'border': '2px solid' + buttonBG,
        'hover': buttonBG,
        'color': buttonBG,
        'border-radius': buttonRadius,
        '--button-hover': buttonBG,
        '--button-hover-color': buttonText
    }"

          :class="buttonStyle"
          target="_blank"
      >
        <i
            v-if="p.icon"
            :class="p.icon"
        />
        {{ p.text }}
      </BLink>
    </div>
  </div>
</template>

<script setup>
defineProps({
  titleText: {
    type: String,
    required: true,
  },
  products: {
    type: Array,
    required: true,
  },
  buttonBG: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
  buttonRadius: {
    type: String,
    required: true,
  },
  buttonStyle: {
    type: String,
    required: true,
  },
});
</script>

<style scoped lang="scss">

</style>